<template>
  <v-app ref="anchor1">
    <v-app-bar
      app
    >
      <v-app-bar-nav-icon></v-app-bar-nav-icon>
      <v-toolbar-title>
        <div class="brand">
          <img class="brand" src="./assets/brand.png" alt="">
          Tradeasy
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn plain @click="goToAnchor('anchor1')">Home</v-btn>
      <v-btn plain @click="goToAnchor('dl')">Download</v-btn>
      <v-btn plain @click="goToAnchor('anchor2')">About Us</v-btn>
      <v-btn plain @click="goToAnchor('anchor3')">Contact</v-btn>
    </v-app-bar>
    <v-main app>
      <v-img src="./assets/banner.png" max-height="600">
        <v-carousel v-model="carouselIndex"
                    height="100%"
                    cycle
                    :show-arrows="false"
                    hide-delimiters
        >
          <v-carousel-item>
            <div class="banner__title">More easy</div>
          </v-carousel-item>
          <v-carousel-item>
            <div class="banner__title">More smart</div>
          </v-carousel-item>
          <v-carousel-item>
            <div class="banner__title">More reliable</div>
          </v-carousel-item>
        </v-carousel>
        <!--        <div class="banner">-->
        <!--          <div class="banner__title">-->
        <!--            <div>More easy</div>-->
        <!--            <div>More smart</div>-->
        <!--            <div>More reliable</div>-->
        <!--          </div>-->
        <!--        </div>-->
      </v-img>
      <v-container>
        <v-sheet class="home-sheet" ref="anchor2">
          <v-row class="home-block">
            <v-col :cols="12" :sm="6">
              <v-img src="./assets/img-0.png" width="100%"></v-img>
            </v-col>
            <v-col :cols="12" :sm="6" style="display: flex;flex-direction: column; justify-content: space-between">
              <h2>About Us</h2>
              <div><b>Tradeasy APP is a client that connects to the Singapore Asia Pacific Exchange through Straits
                Financial.</b></div>
              <div>Investors can trade APEX's AUP (gold perpetual futures) contracts through the APP.</div>
              <div>The APP provides functions such as commodity market analysis, entrusted quotation, position opening,
                closing position, and fund inquiry.
              </div>
            </v-col>
          </v-row>
        </v-sheet>
        <div class="dl-bar" ref="dl">
          <a href="https://play.google.com/store/apps/details?id=com.tradeasy.app" target="_blank">
            <v-img src="./assets/google-play-badge.png" contain height="48" width="160"/>
          </a>
          &emsp;
          <a href="https://apps.apple.com/sg/app/tradeasy/id1613309088" target="_blank">
            <v-img src="./assets/apple-store-badge.svg" contain height="48" width="160"/>
          </a>
          <!--          <v-btn color="primary" href="https://play.google.com/store/apps/details?id=com.tradeasy.app" target="_blank">-->
          <!--            Download Tradeasy-->
          <!--          </v-btn>-->
        </div>
      </v-container>
    </v-main>
    <br>
    <v-footer style="background-color: #474747; padding: 60px 0;" ref="anchor3">
      <v-row class="home-block">
        <v-col style="color: white" align-self="center" :cols="12" :sm="8">
          <h3>Contact</h3>
          <br>
          <div style="font-size: 12px; line-height: 1.6">
            <div>Address：112 ROBINSON ROAD #03-01(068902) SINGAPORE</div>
            <div>Email：asgp@harda.org</div>
          </div>
        </v-col>
        <v-col :cols="12" :sm="4">
          <v-img src="./assets/img-contract.png" contain/>
        </v-col>
      </v-row>
    </v-footer>
    <div class="footer-bar">
      <v-row align="center" justify="space-around">
        <v-col :cols="12" :sm="4" class="footer-bar__title">Partners</v-col>
        <v-col :cols="12" :sm="4">
          <a class="footer-bar__item" target="_blank" href="https://www.asiapacificex.com">
            <!--            <v-img src="./assets/img-p1.png" contain/>-->
            <img src="./assets/img-p1.png" alt="">
          </a>
        </v-col>
        <v-col :cols="12" :sm="4">
          <a class="footer-bar__item" target="_blank" href="https://www.straitsfinancial.com">
            <!--            <v-img src="./assets/img-p2.png" contain/>-->
            <img src="./assets/img-p2.png" alt=""/>
          </a>
        </v-col>
      </v-row>
    </div>
    <div class="footer">
      Copyright@2022 HARDA PTE. LTD. SINGAPORE
    </div>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    carouselIndex: 0,
    form: {
      name: '',
      email: '',
      title: '',
      details: ''
    }
  }),
  methods: {
    goToAnchor (target) {
      this.$vuetify.goTo(this.$refs[target])
    },
    download (channel) {
      console.log(channel)
      // if (channel === 'google') {
      //   location.href = 'https://play.google.com/store/apps/details?id=com.tradeasy.app'
      // } else {
      //
      // }
    }
  }
}
</script>

<style>
html {
  overflow: auto !important;
}

.brand {
  display: flex;
  align-items: center;
}

.brand img {
  display: block;
  height: 42px;
  margin-right: 1rem;
}

.banner {
  margin: auto;
  padding: 1rem;
  max-width: 720px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner__title {
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  font-size: 48px;
  color: white;
  text-shadow: 0 0 20px white;
}

.home-sheet {
  text-align: center;
  padding-top: 4rem;
}

.home-block, .home-block.row {
  margin: auto;
  width: 100%;
  max-width: 720px;
  display: flex;
  justify-content: space-between;
  text-align: left;
}

.home-block .v-card {
  height: 100%;
}

.home-block .v-card__title {
  /*font-size: 16px;*/
}

.home-block .v-card__subtitle, .v-card__text {
  /*font-size: 12px;*/
}

.dl-bar {
  display: flex;
  justify-content: center;
  align-items: stretch;
  text-align: center;
  padding: 4rem 0;
}

.dl-bar a {
  display: block;
  height: 100%;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
  font-size: 12px;
  border-top: 1px solid grey;
}

.footer-bar {
  margin: 2rem auto;
  max-width: 860px;
  text-align: center;
}

.footer-bar__title {
  /*padding: 1rem;*/
  /*min-width: 305px;*/
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
}

.footer-bar__item {
  display: block;
  padding: 1rem;
  /*min-width: 305px;*/
}

.footer-bar__item img {
  display: inline-block;
  width: 80%;
}

</style>
